<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Balance each of the chemical equations shown below. Be sure to include values of 1 for any
        species that only have 1 equivalent involved in the reaction (i.e. empty inputs DO NOT
        correspond to the value "1"). Use integers for ALL coefficients.
      </p>

      <p class="mb-2">
        <b>Reaction 1</b>
      </p>
      <p class="mb-2">
        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.ag"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="Ag(s) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.h2s"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="H2S(g) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.o2"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="O2(g) ->\text{           }" />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.ag2s"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="Ag2S(s) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.h2o"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="H2O(l)" />
        </span>
      </p>

      <p class="mb-2">
        <b>Reaction 2</b>
      </p>

      <p class="mb-2">
        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.fe"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="Fe(s) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.h2oB"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="H2O(l) -> \text{      }" />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.fe3o4"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="Fe3O4(s) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.h2"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="H2(g)" />
        </span>
      </p>

      <p class="mb-2">
        <b>Reaction 3</b>
      </p>
      <p class="mb-2">
        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.al"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="Al(s) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.h2so4"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="H2SO4(aq) -> \text{     } " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.also4"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="Al2(SO4)3(s) + " />
        </span>

        <span class="d-inline-block">
          <v-text-field
            v-model="inputs.h2gas"
            style="width: 45px"
            outlined
            dense
            class="d-inline-block mr-1"
          />
          <chemical-latex style="font-size: 12px" content="H2(g)" />
        </span>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question223',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      // Reaction 1: 4, 2, 1 -> 2, 2
      // Reaction 2: 3, 4 -> 1, 4
      // Reaction 3: 2, 3 -> 1, 3
      inputs: {
        ag: null,
        h2s: null,
        o2: null,
        ag2s: null,
        h2o: null,
        fe: null,
        h2oB: null,
        fe3o4: null,
        h2: null,
        al: null,
        h2so4: null,
        also4: null,
        h2gas: null,
      },
    };
  },
};
</script>
